import React, { useRef, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import {
  MOBILECARIMG,
  MOBILECARIMG2,
  MOBILECARIMG3,
} from "../../assets/images/index";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

const Ourmobileapp = (props) => {
  const Ourmobilecard = [
    {
      image: MOBILECARIMG2,
      title: "Education",
      content: "We build with the best technology stack for mobile application",
    },
    {
      image: MOBILECARIMG3,
      title: "Retail",
      content: "We build with the best technology stack for mobile application",
    },
    {
      image: MOBILECARIMG,
      title: "Traval",
      content: "We build with the best technology stack for mobile application",
    },
  ];

  const ourmob = useRef();
  const [prevslide, setPrevslide] = useState();
  const [nextslide, setNextslide] = useState();

  const Option = {
    loop: false,
    items: 3,
    center: false,
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
    autoplay: false,
    autoplayspeed: 2000,
  };

  return (
    <section className="our-mobileapp py50">
      <Container>
        <Row>
          <Col xl={9} md={9} xs={8} sm={8}>
            <Fade left duration={1000}>
              <div className="heading">
                <h1>OUR MOBILE APP DOMAIN EXPERTISE</h1>
              </div>
            </Fade>
          </Col>
          <Col xl={3} md={3} xs={4} sm={4}>
            <Fade right duration={1000}>
              <div className="nav-icon">
                <BsFillArrowLeftSquareFill
                  className="nav-prev"
                  onClick={() => setPrevslide(ourmob.current.prev())}
                />
                <BsFillArrowRightSquareFill
                  className="nav-next"
                  onClick={() => setNextslide(ourmob.current.next())}
                />
              </div>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="py50">
              <Slide bottom duration={1000}>
                <OwlCarousel
                  {...Option}
                  ref={ourmob}
                  className="owl-theme  owl-carousel"
                  id="owl"
                  // add this line
                >
                  {Ourmobilecard.map((Ourmobilecard, i) => {
                    return (
                      <Card className="mobileapp-card" key={i}>
                        <Card.Img variant="top" src={Ourmobilecard.image} />
                        <Card.Body>
                          <Card.Title>{Ourmobilecard.title}</Card.Title>
                          <Card.Text>{Ourmobilecard.content}</Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </OwlCarousel>
              </Slide>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Ourmobileapp;
