import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./../src/assets/styles/responsive.css";
import Home from "./pages/home";
import Careers from "./pages/careers";
import Portfolio from "./pages/portfolio";
import Blog from "./pages/blog";
import About from "./pages/about";
import Contact from "./pages/contact";
import MobileApp from "./pages/mobile-app";
import OurClient from "./pages/ourClient";
import Blogdetail from "./pages/Blogdetail";
import Cursor from "./component/layout/cursor/Cursor";

const App = (props) => {
  // Function to clear complete cache data
  // caches.keys().then((names) => {
  //   names.forEach((name) => {
  //     caches.delete(name);
  //   });
  // });
  return (
<>
<Cursor />

    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="" index element={<Home  />} />
          <Route path="/:id" element={<About />} />
          <Route path="ourclient" element={<OurClient />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-detail" element={<Blogdetail />} />
          <Route path="portfolio" element={<Portfolio />} />

          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<Contact />} />
          <Route path="mobileapp" element={<MobileApp />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
};

export default App;
