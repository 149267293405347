export const RAINYDAY = require("./rainy-day.jpg");
export const ABOUTUS = require("./aboutus.png");
export const TGPROFILE = require("./man3.jpg");
export const TGQUOTES = require("./TG-Qutes.png");
export const MOBILECARIMG = require ("./mobile-app/mobile-car-img.png");
export const MOBILECARIMG2 = require ("./mobile-app/mobile-car-img2.png");
export const MOBILECARIMG3 = require ("./mobile-app/mobile-car-img3.png");
export const OURTECH = require ("./mobile-app/our-tech.png");
export const ANGULAR = require ("./tools/angular.png");
export const HTML = require ("./tools/html.png");
export const JAVASCRIPT = require ("./tools/javascript.png");
export const NODEJS = require ("./tools/nodejs.png");
export const PHP = require ("./tools/php.png");
export const REACT = require ("./tools/react.png");
export const SQL = require ("./tools/sql.png");
export const JAVA = require ("./tools/java.png");

export const EMPLOYEE = require("./employee.png");
export const EMPLOYEES = require("./employees.png");
export const CLIENT = require("./client.png");
export const PROJECTS = require("./projects.png");
export const IMAGE38 = require("./image38.webp");
export const IMAGE40 = require("./image40.png");
export const LOGO = require("./logo.png");
export const BLOG1 = require("./blog_a.png");
export const BLOG2 = require("./blog_b.png");
export const BLOG3 = require("./blog_c.png");
export const BLOG4 = require("./blog_d.png");
export const BLOG5 = require("./blog_e.png");
export const BLOG6 = require("./blog_f.png");
export const BLOG7 = require("./blog_g.png");
export const BLOG8 = require("./blog_h.png");
export const CALENDER_OUTLINE = require("./calendar-outline.png");
export const CALENDER_WHITE = require("./calender_white.png");
export const BannerImg = require("./banner-img.png");
export const CLIENT1 = require("./clients/client1.png");
export const CLIENT2 = require("./clients/client2.png");
export const CLIENT3 = require("./clients/client3.png");
export const DREAM = require("./dream.png");
export const FOOTERLOGO = require("./white-LOGO.png");
export const PRINTAPP = require("./printapp-logo.png");
export const PUPPYS = require("./puppys.png");
export const NRI = require("./nri.png");
export const WORK1 = require("./works/printapp.png");
export const WORK2 = require("./works/nri.png");
export const WORK3 = require("./works/citadel.png");
export const BGIMAGE = require("./works/bgimage.png");
export const CIRCLE1 = require("./works/circle1.png");
export const CIRCLE2 = require("./works/circle2.png");
export const MOBILEAPP = require("./specialization/mobileapp.png");
export const IOT = require("./specialization/iot.png");
export const WEBAPP = require("./specialization/webapp.png");
export const ONDEMAND = require("./specialization/ondemand.png");
export const DATAANALYTICS = require("./specialization/dataanalytics.png");
export const DIGITAL = require("./specialization/digital.png");
export const SEARCH = require("./why-technogenesis/search.png");
export const BUDGET = require("./why-technogenesis/budget.png");
export const BUSINESS = require("./why-technogenesis/business.png");
export const COMMUNICATIVE = require("./why-technogenesis/communicative.png");
export const MARGET = require("./why-technogenesis/marget.png");
export const PHILOSOPHY = require("./why-technogenesis/philosophy.png");
export const WSEARCH = require("./why-technogenesis/white-search.png");
export const WBUDGET = require("./why-technogenesis/white-budget.png");
export const WBUSINESS = require("./why-technogenesis/white-business.png");
export const WCOMMUNICATIVE = require("./why-technogenesis/white-communicative.png");
export const WMARGET = require("./why-technogenesis/white-marget.png");
export const WPHILOSOPHY = require("./why-technogenesis/white-philosophy.png");
export const CAREER = require("./career.png");
export const PEATALPORT = require("./our1.png");
export const KRPORT = require("./kr-college.png");
export const MOVIEPORT = require("./movieshow.png");
export const PRINTAPPPORT = require("./print-app.png");
export const RASPORT = require("./ras.png");
export const VENUSPORT = require("./venus-services.png");
export const NRIPORT = require("./nrinfo.png");
export const BUSNOWPORT = require("./bus-now.png");
export const BUYWOODSPORT = require("./buywood.png");
export const BUSNOWWHITE = require ("./busnow-white.png");
export const BUYWOODSWHITE = require ("./buywoods-white.png");
export const NRIWHITE = require ("./nri-white.png");
export const PRINTAPPWHITE = require ("./printapp-white.png");
export const PUPPYSWHITE = require ("./puppys-white.png");
export const PUPPYSLOGO = require ("./puppy.png");
export const BUYWOODSLOGO = require ("./buywoods_logo.png");
export const OURCLIENT = require ("./ourclients/client.png");
export const OURCLIENT1 = require ("./ourclients/client1.png");
export const OURCLIENT2 = require ("./ourclients/client2.png");
export const OURCLIENT3 = require ("./ourclients/client3.png");
export const OURCLIENT4 = require ("./ourclients/client4.png");
export const OURCLIENT5 = require ("./ourclients/client5.jpg");
export const OURCLIENT6 = require ("./ourclients/client6.png");
export const OURCLIENT7 = require ("./ourclients/client7.png");
export const OURCLIENT8 = require ("./ourclients/client8.png");
export const OURCLIENT9 = require ("./ourclients/client9.png")
export const OURCLIENT10 = require ("./ourclients/client10.png");
export const OURCLIENT11 = require ("./ourclients/client11.png");
export const OURCLIENT12 = require ("./ourclients/client12.png");
export const OURCLIENT13 = require ("./ourclients/client13.png");
export const OURCLIENT14 = require ("./ourclients/client14.png");
export const OURCLIENT15 = require ("./ourclients/client15.png");
export const OURCLIENT16 = require ("./ourclients/client16.png");
export const OURCLIENT17 = require ("./ourclients/client17.png");
export const OURCLIENT18 = require ("./ourclients/client18.png");
export const OURCLIENT19 = require ("./ourclients/client19.png");
export const OURCLIENT20 = require ("./ourclients/client20.png");
export const OURCLIENT21 = require ("./ourclients/client21.png");
export const OURCLIENT22 = require ("./ourclients/client22.png");
export const OURCLIENT23 = require ("./ourclients/client23.png");
export const OURCLIENT24 = require ("./ourclients/client24.png");
export const OURCLIENT25 = require ("./ourclients/client25.png");
export const OURCLIENT26 = require ("./ourclients/client26.png");
export const OURCLIENT27 = require ("./ourclients/client27.png");
export const OURCLIENT28 = require ("./ourclients/client28.png");
export const OURCLIENT29 = require ("./ourclients/client29.png");
export const OURCLIENT30 = require ("./ourclients/client30.png");
export const OURCLIENT31 = require ("./ourclients/client31.png");
export const OURCLIENT32 = require ("./ourclients/client32.png");
export const OURCLIENT33 = require ("./ourclients/client33.png");
export const OURCLIENT34 = require ("./ourclients/client34.png");
export const OURCLIENT35 = require ("./ourclients/client35.png");
export const OURCLIENT36 = require ("./ourclients/client36.png");
export const OURCLIENT37 = require ("./ourclients/client37.png");
export const OURCLIENT38 = require ("./ourclients/client38.png");
export const OURCLIENT39 = require ("./ourclients/client39.png");
export const OURCLIENT40 = require ("./ourclients/client40.png");
export const BLOGBANNER = require ("./blog-detail-banner.png");
export const BANNERSECBG = require ("./banner_sec_bg.png")
 
export const PINK = require("./pink.png");
export const GREEN = require("./green.png");
export const MOBILEDEV = require("./mobile-app/mobileapp.png");
export const STRATEGY = require("./mobile-app/strategy.png");
export const DESIGNING = require("./mobile-app/designing.png");
export const TESTING = require("./mobile-app/testing.png");
export const DEVELOPMENT = require("./mobile-app/development.png");
export const LAUNCH = require("./mobile-app/launch.png");
export const STRATEGY1 = require("./mobile-app/strategy1.png");
export const DESIGNING1 = require("./mobile-app/designing1.png");
export const TESTING1 = require("./mobile-app/testing1.png");
export const DEVELOPMENT1 = require("./mobile-app/development1.png");
export const LAUNCH1 = require("./mobile-app/launch1.png");
export const STEP1 = require("./mobile-app/step1.png");
export const NATIVE = require("./mobile-app/native.png");
export const HYBRID = require("./mobile-app/hybrid.png");
export const PROGRESSIVE = require("./mobile-app/progressive.png");
export const TGMARGIN = require("./mobile-app/tg-margin.png");
