import React from "react";
import { Container } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
const TitleBanner = ({ bgtitle, title }) => {
  return (
    <Container fluid className="p-0">
<div className="main_title_banner">

<div className="TitleBanner">
        <div className="bg-tile">
        <Fade top cascade  >
          <h1>{bgtitle}</h1>
          </Fade >
        </div>
        <Fade bottom cascade >
        <h2 className="themecolor">{title}</h2>
        </Fade >
      </div>

</div>
     
   
    </Container>
  );
};

export default TitleBanner;
