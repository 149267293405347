import React from 'react'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import  '../../assets/styles/about.css';
import { BsArrowRight } from "react-icons/bs";
const Contactus = () => {
return (
<div>
   <section className="contact_us">
      <Container>
         <div className="bg_image">
            <div className="contact_us_content">
               <h1 className="">Let's Start</h1>
               <h2>Your Business Today !</h2>
               <p>Best Choice for the good communication as setting up extra</p>
               <Button variant="dark" className="mt-50">Contact Us     <BsArrowRight style={{ marginLeft: 6 }} /></Button>
            </div>
         </div>
      </Container>
   </section>
</div>
)
}
export default Contactus;