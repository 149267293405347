import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Fade from "react-reveal/Fade";

import { useNavigate } from "react-router-dom";

import {
  BLOG1,
  BLOG2,
  BLOG3,
  BLOG4,
  BLOG5,
  BLOG6,
  BLOG7,
  BLOG8,
  CALENDER_OUTLINE,
  CALENDER_WHITE,
} from "../assets/images/index";
import { BsArrowRight } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Navigation from "../component/layout/header";
import TitleBanner from "../component/layout/title-banner";
import Contactus from "../component/layout/contactus";
import Testimonials from "../component/layout/testimonials";
import Footer from "../component/layout/footer";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsCloudArrowUpFill } from "react-icons/bs";
import { BsPlusCircle } from "react-icons/bs"
import InputGroup from "react-bootstrap/InputGroup";
import { FileUploader } from "react-drag-drop-files";
import { IoCalendarOutline } from "react-icons/io5";

const Blog = () => {
  // const fileTypes = ["JPEG", "PNG", "GIF"];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let blogDeatil = useNavigate();

  const blogDetail = () => {
    blogDeatil("/blog-detail");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardInfo = [
    {
      image: BLOG1,
      date: "july 24,2021",
      name: "Ganesh Pandi",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG2,
      date: "july 24,2021",
      name: "Satheesh Kumar",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG3,
      date: "july 23,2021",
      name: "Santhosh Narayanan",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG4,
      date: "july 24,2021",
      name: "Kumar",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG5,
      date: "july 24,2021",
      name: "Kumar",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG6,
      date: "july 24,2021",
      name: "Kumar",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG7,
      date: "july 24,2021",
      name: "Kumar",
      designation: "Regional Manager & Limited time management",
    },
    {
      image: BLOG8,
      date: "july 24,2021",
      name: "Kumar",
      designation: "Regional Manager & Limited time management",
    },
  ];
  return (
    <>
      <Navigation />
      <TitleBanner bgtitle={"BLOG"} title={"OUR BLOG"} />

      <Container>
        <Row>
          <div>
            <Button className="blog_button mb-50" onClick={handleShow}>
            <BsPlusCircle  className="mr-14 fs_26"/>Add New Post 
            </Button>
          </div>
          {cardInfo.map((cardInfo, index) => {
            // card section
            return (
              
              <Col xs={12} md={12} lg={6} key={index}>
                <Fade big duration={1000}>
                  <Card className="mb-70 blog_card" onClick={blogDetail}>
                    <div className="blog_image">
                      <Card.Img
                        variant="top"
                        className="blur_effect_add"
                        src={cardInfo.image}
                      />
                      <div className="hover_effect">
                        <Card.Title>
                          <div className="blog_address d-flex align-items-center">
                            <div className="d-flex">
                            <IoCalendarOutline className="blog_icon_2 mr-14" />
                            <h1>
                              <span>{cardInfo.date}</span>
                            </h1>
                            </div>
                            <div className="d-flex ml-50">
                            <i className="fa-solid fa-user mr-14"></i>
                            <h2 className="">
                              <span>{cardInfo.name}</span>
                            </h2>
                            </div>
                          </div>
                          <div className="blog_designation mt-20">
                            {cardInfo.designation}
                          </div>
                        </Card.Title>
                        <Card.Text>{cardInfo.count}</Card.Text>
                      </div>
                    </div>
                    <div className="card_content">
                      <Card.Body>
                        <Card.Title>
                          <div className="blog_address d-flex align-items-center">
                            <div className="d-flex">
                            <IoCalendarOutline className="blog_icon_2 mr-14" />
                            <h1>
                              <span>{cardInfo.date}</span>
                            </h1>
                            </div>
                            <div className="d-flex ml-50">
                            <i className="fa-solid fa-user mr-14"></i>
                            <h2 className="">
                              <span>{cardInfo.name}</span>
                            </h2>
                            </div>
                          </div>
                          <div className="blog_designation mt-20">
                            {cardInfo.designation}
                          </div>
                        </Card.Title>
                        <Card.Text>{cardInfo.count}</Card.Text>
                      </Card.Body>
                    </div>
                  </Card>
                </Fade>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Contactus />
      <Testimonials />
      <Footer />
      <div className="blog_model">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className="border_remove"></Modal.Header>
          <Modal.Title className="title_style add_post">
            <h1>Add Post</h1>
          </Modal.Title>
          <Modal.Body>
            <InputGroup className="mb-3">
              <Form.Control
                className="title_style_add"
                placeholder="Enter Title here"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
            <InputGroup>
              <Form.Control
                className="title_style_add"
                as="textarea"
                placeholder="Enter Text here"
                aria-label="With textarea"
              />
            </InputGroup>
            <div className="file_upload mt-30 ">
              <BsCloudArrowUpFill className="icon_upload" />
              <FileUploader label="Drag and drop here or browse" />
            </div>
          </Modal.Body>
          <Modal.Footer className="blog_footer">
            <Button className="mb-40" variant="dark">
              SUBMIT
              <BsArrowRight style={{ marginLeft: 10 }} />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default Blog;
