import React from "react";
import { Navbar } from "react-bootstrap";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { FOOTERLOGO } from "../../assets/images";
const Footer = ({ routeName }) => {
  return (
    <Navbar bg="light" variant="light" className="footer">
      <Container>
        <Row className="full-width">
          <Col md={12} sm={6} xs={12} lg={6} xl={5} className="">
            <div>
              <img className="footer-logo" src={FOOTERLOGO} alt="logo" />
            </div>
            <div className="mt-25 address">
              <p>50/5, 3rd Floor, MMS Complex, <br />
                Near to Vinayagar Temple, <br />
                VasanthaNagar, Madurai - 625003, <br />
                Tamil Nadu, India.</p>
            </div>
            <div className="social-media">
          <div className="icon">
            <FaLinkedinIn />
          </div>
          <div className="icon">
            <FaFacebook />
          </div>
          <div className="icon">
            <FaInstagram />
          </div>
        </div>
          </Col>
          <Col md={6} sm={6} xs={12} lg={3} xl={3.5}>
            <h3>
              Quick Links
            </h3>
            <ListGroup>
              <ListGroup.Item>
                <a
                  href="/"
                  className={routeName === "Budgeting" ? "active" : ""}
                >
                  Home
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/about"
                  className={routeName === "Credit Card" ? "active" : ""}
                >
                  About Us
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/ourclient"
                  className={routeName === "Personal Loan" ? "active" : ""}
                >
                  Our Clients
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/blog"
                  className={routeName === "Personal Loan" ? "active" : ""}
                >
                  Blogs
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/portfolio"
                  className={routeName === "Cash From Card" ? "active" : ""}
                >
                  Portfolio
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/careers"
                  className={routeName === "Balance Transfer" ? "active" : ""}
                >
                  Careers
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a
                  href="/contact"
                  className={routeName === "Balance Transfer" ? "active" : ""}
                >
                  Contact Us
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={6} sm={6} xs={12} lg={3} xl={3.5}>
            <h3>
            Solutions
            </h3>
            <ListGroup>
              <ListGroup.Item> <a href="/mobileapp">Mobile App Development</a></ListGroup.Item>
              <ListGroup.Item> <a href="">IOT Development</a></ListGroup.Item>
              <ListGroup.Item> <a href="">Web App Development</a></ListGroup.Item>
              <ListGroup.Item> <a href="">Web Design</a></ListGroup.Item>
              <ListGroup.Item> <a href="">Data Analytics</a></ListGroup.Item>
              <ListGroup.Item> <a href="">Digital Marketing</a></ListGroup.Item>
              <ListGroup.Item> <a href="">OnDemand Services</a></ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      <p className="copyright">© Copyright Techno Genesis Software Solutions Private Limited 2022. All Rights Reserved</p>
      </Container>
    </Navbar>
  );
};
export default Footer;
