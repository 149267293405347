import React, {useEffect} from "react";
import Navigation from "../component/layout/header";
import TitleBanner from "../component/layout/title-banner";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import {
 
  OURCLIENT,
  OURCLIENT1,
  OURCLIENT2,
  OURCLIENT3,
  OURCLIENT4,
  OURCLIENT5,
  OURCLIENT6,
  OURCLIENT7,
  OURCLIENT8,
  OURCLIENT9,
  OURCLIENT10,
  OURCLIENT11,
  OURCLIENT12,
  OURCLIENT13,
  OURCLIENT14,
  OURCLIENT15,
  OURCLIENT16,
  OURCLIENT17,
  OURCLIENT18,
  OURCLIENT19,
  OURCLIENT20,
  OURCLIENT21,
  OURCLIENT22,
  OURCLIENT23,
  OURCLIENT24,
  OURCLIENT25,
  OURCLIENT26,
  OURCLIENT27,
  OURCLIENT28,
  OURCLIENT29,
  OURCLIENT30,
  OURCLIENT31,
  OURCLIENT32,
  OURCLIENT33,
  OURCLIENT34,
  OURCLIENT35,
  OURCLIENT36,
  OURCLIENT37,
  OURCLIENT38,
  OURCLIENT39,
  OURCLIENT40,
} from "../assets/images/index";
import "../assets/styles/ourclients.css";
import Contactus from "../component/layout/contactus";
import Testimonials from "../component/layout/testimonials";
import Footer from "../component/layout/footer";

const OurClient = () => {

  useEffect(() => {
  

    window.scrollTo(0, 0)
   

  }, [])


  
  const logo = [
    {
      image: OURCLIENT,
    },
    {
      image: OURCLIENT1,
    },
    {
      image: OURCLIENT2,
    },
    {
      image: OURCLIENT3,
    },
    {
      image: OURCLIENT4,
    },
    {
      image: OURCLIENT5,
    },
    {
      image: OURCLIENT6,
    },
    {
      image: OURCLIENT7,
    },
    {
      image: OURCLIENT8,
    },
    {
      image: OURCLIENT9,
    },
    {
      image: OURCLIENT10,
    },
    {
      image: OURCLIENT11,
    },
    {
      image: OURCLIENT12,
    },
    {
      image: OURCLIENT13,
    },
    {
      image: OURCLIENT14,
    },
    {
      image: OURCLIENT15,
    },
    {
      image: OURCLIENT16,
    },
    {
      image: OURCLIENT17,
    },
    {
      image: OURCLIENT18,
    },
    {
      image: OURCLIENT19,
    },
    {
      image: OURCLIENT20,
    },
    {
      image: OURCLIENT21,
    },
    {
      image: OURCLIENT22,
    },
    {
      image: OURCLIENT23,
    },
    {
      image: OURCLIENT24,
    },
    {
      image: OURCLIENT25,
    },
    {
      image: OURCLIENT26,
    },
    {
      image: OURCLIENT27,
    },
    {
      image: OURCLIENT28,
    },
    {
      image: OURCLIENT29,
    },
    {
      image: OURCLIENT30,
    },
    {
      image: OURCLIENT31,
    },
    {
      image: OURCLIENT32,
    },
    {
      image: OURCLIENT33,
    },
    {
      image: OURCLIENT34,
    },
    {
      image: OURCLIENT35,
    },
    {
      image: OURCLIENT36,
    },
    {
      image: OURCLIENT37,
    },
    {
      image: OURCLIENT38,
    },
    {
      image: OURCLIENT39,
    },
    // {
    //   image: OURCLIENT40,
    // },
  ];

  return (
    <div>
      <Navigation />
      <TitleBanner bgtitle={"CLIENTS"} title={"OUR CLIENTS"} />
      <Container fluid className="our_client_card_section">
        <Container>
          <Row>
            <Col lg={12}>
            <Slide left cascade>
              <div className="our_client_content">
                <h1>Some Heading</h1>
                <p>
                  Supporting our potential customers succeed with Techno
                  Genesis. Our clients are innovative, conservation-focused
                  business leaders.We are helping our clients to solve their
                  problems & promote their business. because our clients trust
                  us almost as much as we trust them.
                </p>
              </div>
              </Slide>

            </Col>
          </Row>

          <div className="our_client_card_container">
            {logo.map((logo, i) => {
              return (
               
                <div className="card_our_clients" key={i}>
                  <img src={logo.image} alt="" className="card_our_clients_img"/>
                  {/* <img src={logo.hoverimge} alt="" className=" clients_img_hover" /> */}
                  
                </div>
              
              );
            })}
          </div>
        </Container>
      </Container>
      <Contactus />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default OurClient;
