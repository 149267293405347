import React, { useEffect, useState } from "react";
import "./../../assets/styles/mobileapp.css";
import Card from "react-bootstrap/Card";
import Footer from "./../../component/layout/footer";
import Navigation from "./../../component/layout/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import {
  MOBILEDEV,
  STRATEGY1,
  DESIGNING1,
  TESTING1,
  DEVELOPMENT1,
  LAUNCH1,
  STRATEGY,
  DESIGNING,
  TESTING,
  DEVELOPMENT,
  LAUNCH,
  STEP1,
  TGMARGIN,
  NATIVE,
  HYBRID,
  PROGRESSIVE,
  OURTECH,
  ANGULAR,
  HTML,
  JAVASCRIPT,
  NODEJS,
  PHP,
  REACT,
  SQL,
  JAVA,
} from "./../../assets/images/index";

import TitleBanner from "./../../component/layout/title-banner";
import Ourmobileapp from "../../component/layout/our-mobileapp";
import Contactus from "./../../component/layout/contactus";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Pulse from "react-reveal/Pulse";
const MobileApp = (props) => {
  const [methodology, setMethodology] = useState("Strategy");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (event) => {
    // 👇️ toggle class on click
    event.currentTarget.classList.toggle("active");
  };

  const methods = [
    {
      title: "Strategy",
      image: STRATEGY,
      hover: STRATEGY1,
      width: "28%",
      classname: "active",
    },
    {
      title: "Designing",
      image: DESIGNING1,
      hover: DESIGNING,
      width: "40%",
    },
    {
      title: "Testing",
      image: TESTING1,
      hover: TESTING,
      width: "40%",
    },
    {
      title: "Development",
      image: DEVELOPMENT1,
      hover: DEVELOPMENT,
      width: "50%",
    },
    {
      title: "Launch",
      image: LAUNCH1,
      hover: LAUNCH,
      width: "40%",
    },
  ];
  const types = [
    {
      title: "Native Apps",
      image: NATIVE,
      content: "Plan the app road map, research competition.",
      bgcolor: "#FEF2EF",
      class: "native",
    },
    {
      title: "Hybrid Apps",
      image: HYBRID,
      content:
        "Analyse the app usability, design a world class user interface.",
      bgcolor: "#F4F5F9",
      class: "hybrid",
    },
    {
      title: "Progressive Apps",
      image: PROGRESSIVE,
      content:
        "Determine the technology platform, define integrate tools and API’s.",
      bgcolor: "#ECF8F0",
      class: "progressive",
    },
  ];

  const tools = [
    {
      tool1: ANGULAR,
      tool2: JAVASCRIPT,
      width: "30%",
    },
    {
      tool1: SQL,
      tool2: NODEJS,
      width: "36%",
    },
    {
      tool1: PHP,
      tool2: REACT,
      width: "36%",
    },
    {
      tool1: HTML,
      tool2: JAVA,
      width: "24%",
    },
  ];

  const Tools = tools.map((tools, index) => {
    return (
      <div key={index}>
        <div className="tool mb-60">
          <img src={tools.tool1} style={{ width: tools.width }} alt="tool" />
        </div>
        <div className="tool">
          <img src={tools.tool2} style={{ width: tools.width }} alt="tool" />
        </div>
      </div>
    );
  });

  const Methods = methods.map((methods, index) => {
    return (
      <Col xs={4} md={2} lg={2} key={index}>
        <Card
          className={methodology === methods.title ? "active" : ""}
          onClick={() => handleClick && setMethodology(methods.title)}
        >
          <Card.Img
            className="default-img"
            src={methods.image}
            style={{ width: methods.width }}
          />
          <Card.Img
            className="hover-img"
            src={methods.hover}
            style={{ width: methods.width }}
          />
          <Card.Body>
            <Card.Text>{methods.title}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  const Types = types.map((type, index) => {
    return (
      <Col xs={12} md={6} sm={6} lg={4} key={index}>
        <Card className={type.class} style={{ background: type.bgcolor }}>
          <Card.Img className="default-img" src={type.image} />
          <Card.Body>
            <Card.Title>{type.title}</Card.Title>
            <Card.Text>{type.content}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1440: {
        items: 4,
      },
    },
  };

  return (
    <>
      <Navigation />
      <TitleBanner bgtitle={"MOBILE"} title={"MOBILE DEVELOPMENT"} />
      <div className="mobile-app-page">
        {/* Mobile app development  */}
        <section className="mobile-app-deve">
          <Container>
            <Row className="my-70" style={{ alignItems: "center" }}>
              <Col xs={12} md={6} lg={6}>
                <div className="about_us_content">
                  <Slide left>
                    <h2 className="m-0">Mobile Application </h2>
                    <h2>Development</h2>
                    <p>
                      Our creative and innovative mobile app developers can
                      provide the mobile app to your requirements, budget as per
                      your timeline.
                    </p>
                  </Slide>
                  <Button variant="dark">Get Started</Button>
                </div>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Slide right cascade>
                  <img className="fluid" src={MOBILEDEV} alt="image1" />
                </Slide>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Dev methodology */}
        <section className="dev-methodology">
          <Container>
            <Fade top>
              <h1 >
                <span className="themeColor">Development</span> Methodology
              </h1>
              <p >
                Our app development process involves the following methods:
              </p>
            </Fade>
            <Fade bottom>
              <Row className="methods">{Methods}</Row>
            </Fade>
          </Container>
        </section>
        {/* Step by step process */}

        <section className="step-by-step-process">
          <Container>
            {methodology === "Strategy" ? (
              <div className="steps">
                <h2>Step-by-Step Mobile App Development Process 01</h2>
                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
                <div>
                  <Zoom>
                    <img src={STEP1} alt="step 1" />
                  </Zoom>
                </div>

                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
              </div>
            ) : (
              <></>
            )}
            {methodology === "Designing" ? (
              <div className="steps">
                <h2>Step-by-Step Mobile App Development Process 02</h2>
                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
                <div>
                  <Zoom>
                    <img src={STEP1} alt="step 1" />
                  </Zoom>
                </div>

                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
              </div>
            ) : (
              <></>
            )}
            {methodology === "Testing" ? (
              <div className="steps">
                <h2>Step-by-Step Mobile App Development Process 03</h2>
                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
                <div>
                  <Zoom>
                    <img src={STEP1} alt="step 1" />
                  </Zoom>
                </div>

                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
              </div>
            ) : (
              <></>
            )}
            {methodology === "Development" ? (
              <div className="steps">
                <h2>Step-by-Step Mobile App Development Process 04</h2>
                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
                <div>
                  <Zoom>
                    <img src={STEP1} alt="step 1" />
                  </Zoom>
                </div>

                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
              </div>
            ) : (
              <></>
            )}
            {methodology === "Launch" ? (
              <div className="steps">
                <h2>Step-by-Step Mobile App Development Process 05</h2>
                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
                <div>
                  <Zoom>
                    <img src={STEP1} alt="step 1" />
                  </Zoom>
                </div>

                <p>
                  We have created our mobile app development process containing
                  9 actionable steps. Our app development follows these steps to
                  develop high-quality mobile apps. ur mobile app development
                  process containing 9 actionable steps. Our app development
                  follows these steps to
                </p>
              </div>
            ) : (
              <></>
            )}
          </Container>
        </section>
        {/* key features */}
        <section className="mobile-app-dev">
          <Container>
            <Row className="my-70" style={{ alignItems: "center" }}>
              <Col xs={12} md={6} lg={6}>
                <Pulse>
                  <img className="fluid mb-20" src={TGMARGIN} alt="image1" />
                </Pulse>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Fade right>
                  <div className="about_us_content mb-20">
                    <h2 className="m-0 homepage-title">
                      TG - Margin In Mobile{" "}
                    </h2>
                    <h2 className="homepage-title">App Development</h2>
                    <h4 className="mt-20">Our Key Features Includes:</h4>
                    <ListGroup as="ol" className="key-features">
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Focus on user experience designing
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Dedicated and trust-able team for each project
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Focus on security & compliance
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Focus on security & compliance
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Focus on scalability & interoperability
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        Efficient support service
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>Accelerated delivery
                      </ListGroup.Item>
                      <ListGroup.Item as="li">
                        <div className="dot"></div>
                        App promotion & margeting
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
        {/* developement type */}
        <section className="dev-types">
          <Container>
            <Fade top>
              <h1 className="homepage-title">
                Development <span className="themeColor">Types</span>
              </h1>
              <p className="homepage-subtitle">
                Our app development process involves the following methods:
              </p>
            </Fade>
            <Fade bottom>
              <Row className="types">{Types}</Row>
            </Fade>
          </Container>
        </section>
        {/* we work on */}
        <section className="we-work-on">
          <Container>
            <Row className="my-70" style={{ alignItems: "center" }}>
              <Col xs={12} md={6} lg={7}>
                <div className="rounded_blur_left"></div>
                <Fade left cascade>
                  <div className="about_us_content">
                    <h1 className="m-0">
                      <b>Our Technology</b>
                    </h1>
                    <h2 className="m-0 homepage-title">
                      Mobile App Technology
                    </h2>
                    <h2 className="homepage-title">We Work On</h2>
                    <p>
                      we build with the best technology stack for mobile
                      application development, we build with the best technology
                      stack for mobile application. we build with the best
                      technology stack for.
                    </p>
                    <p>
                      Mobile application development, we build with the
                      elopment, we build wit.
                    </p>
                    <Button variant="dark">Get Started</Button>
                  </div>
                </Fade>
              </Col>
              <Col xs={12} md={6} lg={5}>
                <div className="our-tech-img">
                  <div className="rounded_blur_shape"> </div>
                  <Zoom>
                    <img className="fluid" alt="image1" src={OURTECH} />
                  </Zoom>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* we use tools */}
        <section className="we-use-tools">
          <Container>
            <Fade top>
              <div className="we-use-tools-content">
                <h1 className="homepage-title">
                  Tools We Use To Develop{" "}
                  <span className="themeColor">Mobile App</span>
                </h1>
                <p className="homepage-subtitle">
                  We build highly functional mobile apps experience in the
                  following domains.
                </p>
              </div>
            </Fade>
            <Fade>
              <div className="py50">
                <OwlCarousel
                  className="owl-theme  owl-carousel tools"
                  id="owl"
                  loop={true}
                  margin={30}
                  nav={true}
                  dots={false}
                  center={false}
                  autoplay={true}
                  autoplayTimeout={3000}
                  smartSpeed={700}
                  responsive={state.responsive}
                >
                  {Tools}
                </OwlCarousel>
              </div>
            </Fade>
          </Container>
        </section>
        <Ourmobileapp />
        <Contactus />
      </div>
      <Footer />
    </>
  );
};
export default MobileApp;
