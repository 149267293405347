import React, { useState, useEffect } from "react";
import Footer from "../component/layout/footer";
import Navigation from "../component/layout/header";
import Contactus from "../component/layout/contactus";
import Fade from 'react-reveal/Fade';

import {
  NRIPORT,
  BUYWOODSPORT,
  BUSNOWPORT,
  VENUSPORT,
  RASPORT,
  PRINTAPPPORT,
  MOVIEPORT,
  KRPORT,
  PEATALPORT,
} from "../assets/images/index";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBanner from "../component/layout/title-banner";
import Testimonials from "../component/layout/testimonials";
import "../assets/styles/portfolio.css";
const Portfolio = (props) => {


  useEffect(() => {
  

    window.scrollTo(0, 0)
   

  }, [])


  const [hover, setHover] = useState({});

  const mouseOver = (event, index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: true,
      };
    });
  };

  const mouseOut = (event, index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: false,
      };
    });
  };
  const work = [
    {
      id: 1,
      image: NRIPORT,
      backclr: "#BFB8E4",

      title: "NRI",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "An ecommerce application, it allows you to buy the electronics products by online mode. The NRI application is make easy way to buy the products online. It gives the payment security; good shopping experience & becomes the effortless home delivery.",

      hover: "NRI_hover ",
    },
    {
      id: 2,
      image: NRIPORT,
      backclr: "#FDCBDD",

      hover: "pup_hover",
      title: "Puppys",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "An ecommerce application, it allows you to buy the electronics products by online mode. The NRI application is make easy way to buy the products online. It gives the payment security; good shopping experience & becomes the effortless home delivery.",
    },
    {
      id: 3,
      image: BUYWOODSPORT,
      backclr: "#E0BFA9",

      hover: "buy_hover",
      title: "Buywoods",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "An ecommerce application, it allows you to buy the electronics products by online mode. The NRI application is make easy way to buy the products online. It gives the payment security; good shopping experience & becomes the effortless home delivery.",
    },
    {
      id: 4,
      image: PEATALPORT,
      backclr: "#BAA7CD",

      hover: "petal_hover",
      title: "Petals Automations",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "An ecommerce application, it allows you to buy the electronics products by online mode. The NRI application is make easy way to buy the products online. It gives the payment security; good shopping experience & becomes the effortless home delivery.",
    },

    {
      id: 4,
      image: BUSNOWPORT,
      backclr: "#E76B74",

      hover: "bus_hover",
      title: "Bus Now",
      subtitle1: "Web & Mobile Application",
      subtitle:"Madurai's Most loved transit application Innovative Design Thinking that lead to marketing this product and made it the most loved transit application in Madurai.",
    },

    {
      id: 5,
      image: KRPORT,
      width1: "78.5%",
      backclr: "#88B4F4",

      hover: "kr_hover",
      title: "KR College",
      subtitle1: "Web Application",
      subtitle:
        "KR College Institute Management Application KR College application created to provide real time tracking the students and teachers details.",
    },

    {
      id: 6,
      image: VENUSPORT,
      backclr: "#C9BDEA",

      hover: "ven_hover",
      title: "Venus Services",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "Enterprise based survey platform Story of building an VQC, finding product qulaity and quanity, monitoring clinets, stakeholders and achieving scale.",
    },
    {
      id: 7,
      image: PRINTAPPPORT,
      backclr: "#D3E5FE",

      hover: "print_hover",
      title: "The Print App",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "World best photo framing app The Print App application makes easy to framing your photos. It gives you opportunity to transform your most loved photos into stylist photo frames, canvas, banner print,etc,. Print App application turns your sweet memories into colourful photos",
    },
    {
      id: 8,
      image: RASPORT,
      backclr: "#FDBDDA",

      hover: "ras_hover",
      title: "RAS Solutions",
      subtitle1: "Web & Mobile Application",
      subtitle:
        "Best leading service in the logistics industry Conspect of a RAS becoming a leading transport in the market place.",
    },
    {
      id: 9,
      image: MOVIEPORT,
      backclr: "#F1A9B2",

      hover: "movie_hover",
      title: "My Movieshow",
      subtitle1: "Web & Mobile Application",

      subtitle:
        "Madurai most comprehensive movie booking tool My movie show makes very easy to find your favourite movie and options to booking movie via online mode very comfortability.",
    },
  ];

  return (
    <>
      <Navigation />

      <TitleBanner bgtitle={"WORKS"} title={"OUR WORKS"} />

      <Container fluid>
        <Row>
          {work.map((work, index) => {
            return (
              <Col lg={6} key={index} className="p-0">
                <Fade big>

                
                <Card
                  onMouseEnter={(e) => {
                    mouseOver(e, index);
                  }}
                  onMouseLeave={(e) => {
                    mouseOut(e, index);
                  }}
                  className={
                    hover
                      ? work.hover
                      : "our_work_card d-flex alignItemCenter justifyContentCenter"
                  }
                  style={{ background: work.backclr }}
                >
                  <Card.Img
                    variant=""
                    className={"our_work_img"}
                    src={work.image}
                    style={{ width: work.width1 }}
                  />

                  <div className="new_hover hover_text">
                    <div className="text-center ">
                      <h2>{work.title}</h2>
                      <h3>{work.subtitle1}</h3>
                      <p>{work.subtitle}</p>
                    </div>
                  </div>
                </Card>
                </Fade>
                {/* {isHover && (
                  <Card
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                    className="our_work_card our_card_1 d-flex alignItemCenter justifyContentCenter"
                    style={{ background: work.backclr, height: work.height }}
                  >
                    <h1>{work.title}</h1>
                    <p>{work.subtitle}</p>
                  </Card>
                )} */}
              </Col>
            );
          })}
        </Row>
      </Container>
      <Contactus />
      <Testimonials />

      <Footer />
    </>
  );
};

export default Portfolio;
