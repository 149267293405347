import React from "react";

import "./../../App.css";
import Card from "react-bootstrap/Card";

const TestimonialCard = ({
  bgColor,
  image,
  content,
  profile_img,
  client_detail,
  client_name,
  back,
  width1

}) => {
  return (
    <>
      <div className="single-card py50" style={{ background: bgColor }}>
        <Card
          className="testimonial_carousal_card_width"
          style={{
            width: "400px",
            background: back,
            border: "none",
            borderRadius: "10px",
            margin: "auto",
          }}
        >
          <Card.Body className="">
            <Card.Img
              variant="top"
              className="testimonial_top_img"
              src={image}
              style={{width: width1 }}
             
            />
            <Card.Text className="testimonial_main_content pt-30">
              "{content}"
            </Card.Text>
            <div className="d-flex alignItemCenter ">
              <Card.Img
                className="testimonial_profile_imge"
                variant=""
                src={profile_img}
                width="10%"
              />
              <div className="ml-15">
                <Card.Text className="testimoinal_client_name">
                  {client_name}
                </Card.Text>
                <Card.Text className="testimoinal_client_detail">
                  {client_detail}
                </Card.Text>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default TestimonialCard;
