import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Footer from "../component/layout/footer";
import Navigation from "../component/layout/header";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsArrowRight } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import TitleBanner from "../component/layout/title-banner";

import ReactCSSTransitionGroup from "react-transition-group";

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = ["Italy", "Spain", "Greece"];

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Interested In");
  return (
    <>
      <Navigation />

      <TitleBanner bgtitle={"CONTACT"} title={"GET IN TOUCH"} />
      <Container className="mt-50">
        <Row className="contact">
          <Col xs={12} md={12} lg={12} xl={6}>
            <div className="contact_form_left">
              <h1>Send a Message</h1>
              <p className="mt-20">
                Best choice for the good communication as setting up extra calls
                and sending...Best choice for the good.
              </p>
              <div
                className="flex_remove"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FloatingLabel
                  style={{ width: "48%", fontWeight: "bold" }}
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3 change_form"
                  variant="fill"
                >
                  <Form.Control
                    className="form_style"
                    type="rext"
                    placeholder="name@example.com"
                  />
                  <span className="focus_border"></span>
                </FloatingLabel>

                <FloatingLabel
                  style={{ width: "48%", fontWeight: "bold" }}
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3 change_form"
                  variant="fill"
                >
                  <Form.Control
                    className="form_style"
                    type="email"
                    placeholder="name@example.com"
                  />
                </FloatingLabel>
              </div>
              <div
                className="flex_remove"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="dropdown_contact_us">
                  <div
                    onClick={(e) => {
                      setIsActive(!isActive);
                    }}
                    className="dropdown_btn_contact"
                  >
                    {selected}
                    <span
                      className={
                        isActive ? "fas fa-caret-right" : "fas fa-caret-down"
                      }
                    />
                  </div>

                  <div className={isActive ? "polu" : "polu"}>
                    <div
                      className={
                        isActive ? "dropdown-content" : "dropdown-content_1"
                      }
                    >
                      <div
                        onClick={(e) => {
                          setIsSelected(e.target.textContent);
                          setIsActive(!isActive);
                        }}
                        className="contactdrop_item"
                      >
                        <p>UI/UX Designer </p>
                      </div>
                      <div
                        className="contactdrop_item"
                        onClick={(e) => {
                          setIsSelected(e.target.textContent);
                          setIsActive(!isActive);
                        }}
                      >
                        <p>UI Developer</p>
                      </div>
                      <div
                        className="contactdrop_item"
                        onClick={(e) => {
                          setIsSelected(e.target.textContent);
                          setIsActive(!isActive);
                        }}
                      >
                        <p>IOS Developer</p>
                      </div>
                    </div>
                  </div>
                </div>

                <FloatingLabel
                  style={{ width: "48%", fontWeight: "bold" }}
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3 change_form"
                  variant="fill"
                >
                  <Form.Control
                    className="form_style"
                    type="number"
                    placeholder="name@example.com"
                  />
                </FloatingLabel>
              </div>
              <div className="">
                <FloatingLabel
                  style={{ fontWeight: "bold" }}
                  controlId="floatingTextarea2"
                  label="Message"
                >
                  <Form.Control
                    className="form_style"
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </div>
              <Button className="mt-50" variant="dark">
                Contact Us
                <BsArrowRight style={{ marginLeft: 10 }} />
              </Button>
            </div>
          </Col>
          <Col xs={12} md={12} lg={12} xl={6}>
            <div className="contact_form_right">
              <h2>Call Us</h2>
              <h3 className="phone_number_style number-style">
                <span style={{ marginRight: 14 }}>
                  <BsFillTelephoneFill />
                </span>
                +91 - 8978765654 / +91 - 7867654508
              </h3>
              <div className="email">
                <h2 className="mt-50">Email Us</h2>
                <h4 className="mt-20 mb-16">
                  For Business:sales@technogenesis.in
                </h4>
                <h4 className="mb-16">For Information:info@technogenesis.in</h4>
                <h4 className="mb-16">Skype Id:info@technogenesis.in</h4>
              </div>
              <div className="visit_section">
                <h2 className="mt-50">Visit Us</h2>
                <h3
                  className="phone_number_style"
                  style={{ color: "#303030", fontWeight: 500, lineHeight: 1.5 }}
                >
                  <span
                    style={{
                      marginRight: 18,
                      color: "#EC7002 !important",
                    }}
                  >
                    <i
                      className="fa-solid fa-location-dot color_change"
                      style={{ fontSize: 18 }}
                    ></i>
                  </span>
                  50/5 , 3rd Floor, MMS Complex, Near to Vinayagar
                  Temple,Vasanth Nagar,Madurai - 625003,
                </h3>
              </div>
            </div>
          </Col>
        </Row>
        <iframe
          className="my-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.331017160713!2d78.09690801461467!3d9.906364292915393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf6e4a68a983%3A0x77238e8baffba37d!2sTechno%20Genesis%20Software%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1665407186405!5m2!1sen!2sin"
          width={"100%"}
          height={450}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Container>
      <Footer />
    </>
  );
};
export default Contact;
