import React, { useEffect } from "react";
import "../assets/styles/career.css";
import Footer from "../component/layout/footer";
import Navigation from "../component/layout/header";
import TitleBanner from "../component/layout/title-banner";
import Testimonials from "../component/layout/testimonials";
import { CAREER } from "../assets/images";
import { Container, Accordion, Row, Col } from "react-bootstrap";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { TGPROFILE } from "../assets/images/index";
import Contactus from "../component/layout/contactus";
import Lifeoftgcard from "../component/layout/lifeoftg";
import Zoom from "react-reveal/Zoom";

import Slide from "react-reveal/Slide";

const Careers = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Tglife = [
    {
      id: 1,
      profile: TGPROFILE,
      content1:
        "Having built over 120+ apps on almost every single platform and device, we can make your dream a reality",
      profileName: "Kumar Pandi",
      domain: "UI Developer",
    },
    {
      id: 2,
      profile: TGPROFILE,
      content1:
        "Having built over 120+ apps on almost every single platform and device, we can make your dream a reality",
      profileName: "Kumar Pandi",
      domain: "UX Developer",
    },
  ];

  const lifetg = Tglife.map((Tglife) => (
    <div className="item" key={Tglife.id}>
      <Lifeoftgcard
        profile={Tglife.profile}
        content1={Tglife.content1}
        profileName={Tglife.profileName}
        domain={Tglife.domain}
      />
    </div>
  ));

  const state = {
    responsive: {
      0: {
        items: 1,
        center: true,
      },
      450: {
        items: 1,
        center: true,
      },
      600: {
        items: 1,
        center: true,
      },
      1000: {
        items: 1,
      },
      1200: {
        items: 2,
      },
    },
  };

  return (
    <>
      <Navigation />
      <TitleBanner bgtitle={"JOIN OUR TEAM"} title={"CAREER"} />
      <Container>
        <div className="my-100 join-us">
          <div className="career-img">
            <Zoom>
              <img src={CAREER} alt="imge" />
            </Zoom>
          </div>
          <Zoom cascade>
            <div className="career-content">
              <h1>
                IF YOU WANT TO <span className="themeColor">JOIN</span> OUR
                TEAM?
              </h1>
              <p>
                Having built over 120+ apps on almost every single platform and
                device, we can make your dream a reality
              </p>
            </div>
          </Zoom>
          <Slide right>
            <div className="workflow">
              <div className="process">
                <div className="count">
                  <h2>01</h2>
                  <div className="bar"></div>
                </div>
                <h3>Written Test</h3>
              </div>
              <div className="process">
                <div className="count">
                  <h2>02</h2>
                  <div className="bar"></div>
                </div>
                <h3>Technical Test</h3>
              </div>
              <div className="process">
                <div className="count">
                  <h2>03</h2>
                  <div className="bar"></div>
                </div>
                <h3>System Test</h3>
              </div>
              <div className="process">
                <div className="count">
                  <h2>04</h2>
                  <div className="bar"></div>
                </div>
                <h3>HR</h3>
              </div>
              <div className="process">
                <div className="count">
                  <h2>05</h2>
                </div>
                <h3>Offer</h3>
              </div>
            </div>
          </Slide>
        </div>
      </Container>
      <section className="position pb-50">
        <Container>
          <div className="">
            <div className="career-content">
              <h1>OPEN POSITIONS</h1>
              <p>Having built over 120+ apps on almost</p>
            </div>
            <Row className="full-width">
              <Col md={12} sm={12} xs={12} lg={6} xl={4} className="">
                <div className="all-dept">
                  <div className="departement">
                    <h3 className="themeColor">Departments</h3>
                    <span className="icon">
                      <MdOutlineArrowForwardIos />
                    </span>
                  </div>
                  <p>All Department</p>
                </div>
              </Col>
              <Col
                md={12}
                sm={12}
                xs={12}
                lg={6}
                xl={8}
                className="pl-52 bl-line"
              >
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Server Administrator</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Node JS Developer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>React Native Developer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Block Chain Developer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>UI Developer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Java Developer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Software Tester</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>UI/UX Designer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>Graphic Designer</Accordion.Header>
                    <Accordion.Body>
                      <div className="job-detail">
                        <div className="mt-20">
                          <h3>Experience</h3>
                          <p>1 to 2+ Years of Exp</p>
                        </div>
                        <div className="mt-20 pb-20 bb-line">
                          <h3>Qualifications</h3>
                          <p>Bachelor's (preferred)</p>
                        </div>
                        <div className="mt-20 pt-20">
                          <h3>Full Job Description</h3>
                          <h4 className="themeColor mt-20">Job Description:</h4>
                          <ul>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                            <li>
                              The point of using Lorem Ipsum is that it has a
                              more-or-less normal distribution of letters, as
                              opposed to using 'Content here, content here',
                              making it look like readable English.
                            </li>
                            <li>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </li>
                          </ul>
                        </div>
                        <div className="bb-line">
                          <h4 className="themeColor mt-20">
                            Technical Skills:
                          </h4>
                          <ul>
                            <li>
                              Coding skills with C/C++ language, working with
                              the real-time operating system is a plus
                            </li>
                            <li>
                              Proficiency in working on Window/Linux operating
                              system
                            </li>
                          </ul>
                        </div>
                        <div className="mt-20 pb-20">
                          <h3>Schedule:</h3>
                          <p>Morning Shift</p>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="pb-50 mb-50">
        <Container fluid>
          <div className="career-content">
            <h1>
              LIFE OF <span className="themeColor">TG</span>
            </h1>
          </div>
          <OwlCarousel
            className="owl-theme tg-owl"
            loop={true}
            margin={10}
            items={2}
            nav={false}
            startPosition={5}
            dots={false}
            autoplay={true}
            autoplayTimeout={2000}
            responsive={state.responsive}
            navText={[
              '<div className="arrow prev"><i className="far fa-arrow-alt-circle-left"></i></div>',
              '<div className="arrow next"><i className="far fa-arrow-alt-circle-right"></i></div>',
            ]}
            center={false}
          >
            {lifetg}
          </OwlCarousel>
        </Container>
      </section>
      <Contactus />
      <div className="testimonial">
        <Testimonials />
      </div>

      <Footer />
    </>
  );
};
export default Careers;
