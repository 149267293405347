import React, { useState } from "react";
import { Navbar, Nav, Container, Offcanvas, NavDropdown } from "react-bootstrap";
import { LOGO } from "./../../assets/images/index";
import { Link, useNavigate } from "react-router-dom";
const Navigation = ({ routeName }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const  contactpage = useNavigate()

  const [navbar, setNavbar] = useState(false);

  const changebg = () => {
    if (window.scrollY >= 50) {
      setNavbar(true)
    }
    else {
      setNavbar(false)
    }
  }
  window.addEventListener("scroll", changebg)

const contact = () =>{
  contactpage('/contact')

}

  return (

    <Navbar variant="light" className={navbar ? "navchange active" : "navchange"}>
      <Container>
        <Navbar.Brand href="/">
          <img src={LOGO} width="150" height="60" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end" onHide={handleClose} responsive="lg"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel" className="themeColor">
              Techno Genesis
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Navbar.Collapse className="justify-content-end mobile">
              <Nav className="w85 justify-content-end">
                <Link
                  to="/"
                  className={routeName === "Budgeting" ? "active" : ""}>
                  Home
                </Link>
                <NavDropdown title="About Us" id="navbarScrollingDropdown">
                  <div className="animate slideIn">
                    <NavDropdown.Item>
                      <span> <Link to="/about">Who We Are</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span> <Link to="/ourclient">Our Clients</Link></span>
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>

                <NavDropdown title="Solutions" id="navbarScrollingDropdown">
                  <div className="animate slideIn">
                    <NavDropdown.Item>
                      <span> <Link to="/mobileapp">Mobile App Development</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span><Link to="/">IOT Development</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span><Link to="/">Web App Development</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span><Link to="/">Web Design</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span><Link to="/">Data Analytics</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span> <Link to="/">Digital Marketing</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span> <Link to="/">OnDemand Services</Link></span>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <span><Link to="/">Web App Development</Link></span>
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
                <Link
                  to="/blog"
                  className={routeName === "Credit Card" ? "active" : ""}
                >
                  Blogs
                </Link>
                <Link
                  to="/portfolio"
                  className={routeName === "Portfolio" ? "active" : ""}
                >
                  Portfolio
                </Link>
                <Link to="/careers">Careers</Link>
                <Link to="/contact">Contact Us</Link>
              </Nav>
            </Navbar.Collapse>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <div className="desktop">
          <Navbar.Collapse className="justify-content-end ">
            <Nav className="justify-content-end ">
              <Link
                to="/"
                className={routeName === "Budgeting" ? "active" : ""}>
                Home
              </Link>

              <NavDropdown title="About Us" id="navbarScrollingDropdown" >
                <div className="animate slideIn">
                  <NavDropdown.Item>
                    <span> <Link to="/about">Who We Are</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/ourclient">Our Clients</Link></span>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>

              <NavDropdown title="Solutions" id="navbarScrollingDropdown">
                <div className="animate slideIn">
                  <NavDropdown.Item>
                    <span> <Link to="/mobileapp">Mobile App Development</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">IOT Development</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span><Link to="/">Web App Development</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">Web Design</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">Data Analytics</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">Digital Marketing</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">OnDemand Services</Link></span>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <span> <Link to="/">Web App Development</Link></span>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <Link
                to="/blog"
                className={routeName === "Credit Card" ? "active" : ""}
              >
                Blogs
              </Link>
              <Link
                to="/portfolio"
                className={routeName === "Credit Card" ? "active" : ""}
              >
                Portfolio
              </Link>
              <Link to="/careers">Careers</Link>
            </Nav>

            <div className="contact-us" onClick={contact}>
              <Link to="/contact">Contact Us</Link>
            </div>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
};
export default Navigation;



