import React, { useEffect } from "react";
import "../assets/styles/about.css";
import Footer from "../component/layout/footer";
import Navigation from "../component/layout/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { ABOUTUS } from "../assets/images/index";
import OurHighlights from "./our-highlights";
import TitleBanner from "../component/layout/title-banner";
import Contactus from "../component/layout/contactus";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Testimonials from "../component/layout/testimonials";
const About = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <TitleBanner bgtitle={"ABOUT US"} title={"WHO WE ARE"} />
      <OurHighlights />
      <section className="who_we_are">
        <Container>
          <Row className="my-70" style={{ alignItems: "center" }}>
            <Col xs={12} md={6} lg={6}>
              <div className="rounded_blur_left"></div>
              <div className="about_blur_bg">
                <h3>About</h3>
              </div>
              <div className="about_us_content">
                <Fade left>
                  <h1 className="m-0">Who we are</h1>
                  <h2 className="m-0">We Are The Best</h2>

                  <h2>IT Solution</h2>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </Fade>
                <Button variant="dark">Get Started</Button>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className="rounded_blur_right"> </div>
              <Zoom>
                <img className="fluid" src={ABOUTUS} alt="image1" />
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>
      <Contactus />
      <Testimonials />
      <Footer />
    </>
  );
};
export default About;
