import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts/circular-std/CircularStd-Black.ttf";
import "./fonts/circular-std/CircularStd-Bold.ttf";
import "./fonts/circular-std/CircularStd-Book.ttf";
import "./fonts/circular-std/CircularStd-Medium.ttf";
import "./fonts/avenir/AvenirLTStd-Black.otf";
import "./fonts/avenir/AvenirLTStd-Book.otf";
import "./fonts/avenir/AvenirLTStd-Roman.otf";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
