import React, { useRef, useEffect } from "react";
import { useState } from "react";
// import "./../../../App.css";
import "../../../assets/styles/cursor.css";

function Cursor() {
  const cursorRef = useRef(null);
  const secondRef = useRef(null);

const [bg,setBg ] = useState()

  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });
  
   useEffect(() => {
    document.addEventListener("mousemove", (event) => {
      const { clientX, clientY } = event;

      const mouseX = clientX;
      const mouseY = clientY;

      positionRef.current.mouseX = mouseX - secondRef.current.clientWidth / 2;
      positionRef.current.mouseY = mouseY - secondRef.current.clientHeight / 2;
      cursorRef.current.style.transform = `translate3d(${
        mouseX - cursorRef.current.clientWidth / 2
      }px, ${mouseY - cursorRef.current.clientHeight / 2}px, 0)`;
    });

    return () => {};
  }, []);

  useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);

      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;

      if (!destinationX | !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;

        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }

      secondRef.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0 )`;
    };
    followMouse();
    
  }, []);

  // const hover = () => {



    





  //   document.querySelectorAll("[data-cursor]").forEach((item) => {
  //       item.addEventListener("mouseover", (e) => {
  //         if (item.dataset.cursor == "pointer") {
  //           secondRef.current.style.backgroundColor = "red";
           
  //         }
  //         if (item.dataset.cursor == "pointer2") {
  //           secondRef.current.style.backgroundColor  = "green";
          
  //         }
  //       });
  //       item.addEventListener("mouseout", (e) => {
  //           secondRef.current.style.backgroundColor  = "unset";
         
  //       });
  //     });



  // }

  // useEffect(() => {
  //   hover()
  
  //   return () => {
      
  //   }
  // }, [1])
  



  return (
    // main-cursor
    // secondary-cursor
    // second_cursor
    // app_cursor
    <div>
      <div className="app_cursor" ref={cursorRef}>
        {/* <div className="main-cursor-background"></div> */}
      </div>
      <div className="second_cursor " ref={secondRef}>
        {/* <div className="cursor-background"></div> */}
      </div>
    </div>
  );
}

export default Cursor;
