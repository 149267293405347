import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TestimonialCard from "./testimonial-card";
import { NRI, PUPPYS, IMAGE38, PRINTAPP } from "./../../assets/images/index";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
const Testimonials = (props) => {
  const car = useRef();

  const [prev, setPrev] = useState();
  const [next, setNext] = useState();

  const testimonials = [
    {
      title: "FILL IN ONCE",
      content:
        "They have a tremendous abilit to understand your vision behind the project and they become part of your they have excapablities",
      image: PRINTAPP,
      client_name: "Vijay",
      client_detail: "Founder, PrintApp Studio",
      profile_img: IMAGE38,
      back: "#E6F0F6",
      width1: "6rem",
    },
    {
      title: "REQUEST",
      content:
        "They have a tremendous abilit to understand your vision behind the project and they become part of your they have excapablities",
      image: NRI,
      client_name: "Satheesh",
      client_detail: "Founder, NRI",
      profile_img: IMAGE38,
      back: "#FCF0E4",
      width1: "9rem",
    },
    {
      title: "CHILL",
      content:
        "They have a tremendous abilit to understand your vision behind the project and they become part of your they have excapablities",
      image: PUPPYS,
      client_name: "Rajesh",
      client_detail: "Founder, Puppy's",
      profile_img: IMAGE38,
      back: "#E8EAE9",
      width1: "9rem",
    },
    {
      title: "CHILL",
      content:
        "They have a tremendous abilit to understand your vision behind the project and they become part of your they have excapablities",
      image: PUPPYS,
      client_name: "Rajesh",
      client_detail: "Founder, PrintApp",
      profile_img: IMAGE38,
      back: "#E8EAE9",
      width1: "9rem",
    },
  ];

  const headeing = [
    {
      title: "Testimonial",
      subtitle:
        "Best Choice for good commuincation as setting up extra calls and Sending...Best choice for the good",
    },
  ];

  const Testimonials = testimonials.map((testimonial, i) => (
    <div className="item" key={i}>
      <TestimonialCard
        title={testimonial.title}
        content={testimonial.content}
        image={testimonial.image}
        profile_img={testimonial.profile_img}
        client_name={testimonial.client_name}
        client_detail={testimonial.client_detail}
        back={testimonial.back}
        width1={testimonial.width1}
      />
    </div>
  ));

  const state = {
    responsive: {
      0: {
        items: 1,
        center: true,
      },
      450: {
        items: 1,
        center: true,
      },
      600: {
        items: 1,
        center: true,
      },
      995: {
        items: 2,
        center: false,
      },
      1000: {
        items: 3,
      },
    },
  };

  const events = {
    onDragged: function (event) {
      console.log("onDragged: " + event.type);
    },
    onChanged: function (event) {
      console.log("onChanged: " + event.type);
    },
    onTranslate: function (event) {
      console.log("onTranslate: " + event.type);
    },
  };

  return (
    <>
      <div className="" style={{ marginTop: "10%" }}>
        <div style={{ background: "#fff", paddingBottom: 60 }} className="">
          <Container>
            <Row>
              <Col xs={8} lg={6}>
                {headeing.map((headeing, index) => {
                  return (
                    <Fade left duration={1000}>
                      <div className="header" key={headeing}>
                        <h2 className="testimonial_section_header">
                          {headeing.title}
                        </h2>
                        <h4 className="testimonial_section_subheader">
                          {headeing.subtitle}
                        </h4>
                      </div>
                    </Fade>
                  );
                })}
              </Col>
              <Col xs={4} lg={6}>
                <Fade right duration={1000}>
                  <div className="testimonial_custom_nav text-end">
                    <BsArrowLeft
                      className="testimonial_custom_nav_prev arrow_conatiner "
                      onClick={() =>
                        setPrev(
                          car.current.prev(),

                          console.log(car.current.prev(), ">>>>>>>")
                        )
                      }
                    />
                    <BsArrowRight
                      className="testimonial_custom_nav_next arrow_conatiner "
                      onClick={() => setNext(car.current.next())}
                    />
                  </div>
                </Fade>
              </Col>
            </Row>

            <Row>
              <Col>
                <Slide bottom duration={1000}>
                  <div className="testimonial_caro">
                    <div className="filter_circle_1"></div>
                    <div className="filter_circle_2"></div>
                    <OwlCarousel
                      ref={car}
                      className="owl-theme  owl-carousel"
                      events={events}
                      id="owl"
                      loop={true}
                      margin={10}
                      items={3}
                      dots={false}
                      center={true}
                      autoplay={false}
                      autoplayTimeout={6000}
                      smartSpeed={1000}
                      responsive={state.responsive}
                    >
                      {Testimonials}
                    </OwlCarousel>
                  </div>
                </Slide>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Testimonials;
