import React, {useEffect} from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./../assets/styles/about.css";
import {
  BannerImg,
  CLIENT1,
  CLIENT2,
  CLIENT3,
  DREAM,
  WORK1,
  WORK2,
  WORK3,
  MOBILEAPP,
  IOT,
  WEBAPP,
  ONDEMAND,
  DATAANALYTICS,
  DIGITAL,
  BUDGET,
  BUSINESS,
  COMMUNICATIVE,
  MARGET,
  PHILOSOPHY,
  BGIMAGE,
  SEARCH,
  WBUDGET,
  WBUSINESS,
  WCOMMUNICATIVE,
  WMARGET,
  WPHILOSOPHY,
  WSEARCH,
  CIRCLE1,
  CIRCLE2,
} from "./../assets/images/index";
import loadable from "@loadable/component";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Contactus from "../component/layout/contactus";
import Carousel from "react-bootstrap/Carousel";
import Fade from 'react-reveal/Fade';
const Navigation = loadable(() => import("../component/layout/header"));
const Banner = loadable(() => import("../component/layout/banner"));
const Specialization = loadable(() =>
  import("../component/layout/specialization")
);
const WhyTechno = loadable(() => import("../component/layout/why-techno"));
const Testimonials = loadable(() => import("../component/layout/testimonials"));
const Footer = loadable(() => import("../component/layout/footer"));


const Home = (props) => {


  useEffect(() => {
  

    window.scrollTo(0, 0)
   

  }, [])

  const logos = [
    {
      id:1,
      image: CLIENT1,
    },
    {
      id:2,
      image: CLIENT2,
    },
    {
      id:3,
      image: CLIENT3,
    },
    {
      id:4,
      image: CLIENT1,
    },
    {
      id:5,
      image: CLIENT2,
    },
    {
      id:6,
      image: CLIENT3,
    },
  ];
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  const works = [
    {
      id:1,
      image: WORK1,
    },
    {
      id:2,
      image: WORK3,
    },
    {
      id:3,
      image: WORK2,
    },
  ];
  const specials = [
    {
      id:1,
      image: MOBILEAPP,
      title: "Mobile App Development",
      subtitle:
        "Techno Genesis is a leading mobile application development company. This is the...",
      button: "Read More",
      routeName: "Home",
      bgColor: "#e6f0f6",
    },
    {
      id:2,
      image: IOT,
      title: "IOT Development",
      subtitle:
        "Techno Genesis is a leading IOT development company. This is the...",
      button: "Read More",
      routeName: "Home",
      bgColor: "#f7f5f4",
    },
    {
      id:3,
      image: WEBAPP,
      title: "Web App Development",
      subtitle:
        "Techno Genesis is a leading web application development company. This is the...",
      button: "Read More",
      routeName: "Home",
      bgColor: "#f7f5f4",
    },
    {
      id:4,
      image: ONDEMAND,
      title: "On-Demand Services",
      subtitle:
        "Today world is all about collaborating and growing together. Techno Genesis...",
      button: "Read More",
      routeName: "Home",
      bgColor: "#fcf0e4",
    },
    {
      id:5,
      image: DATAANALYTICS,
      title: "Data Analytics",
      subtitle:
        "We provide data analytics service synchronize your data with the tactical and operational...",
      button: "Read More",
      routeName: "Home",
      bgColor: "#f7f5f4",
    },
    {
      id:6,
      image: DIGITAL,
      title: "Digital Marketing",
      subtitle:
        "We help you to expanding your brands in multiple platforms by digital marketing",
      button: "Read More",
      routeName: "Home",
      bgColor: "#f7f5f4",
    },
  ];
  const whyustg = [
    {
      id:1,
      image: SEARCH,
      hoverimage: WSEARCH,
      title: "Figure Out Your Needs",
      subtitle:
        "We are to ready to solve what are that exact problems and expectations of client's...",
      button: "Read More",
      routeName: "Home",
    },
    {
      id:2,
      image: BUDGET,
      hoverimage: WBUDGET,
      title: "Business Approach",
      subtitle:
        "We build a complete overview of client project. Based on this, we realize the elasticity to...",
      button: "Read More",
      routeName: "Home",
    },
    {
      id:3,
      image: BUSINESS,
      hoverimage: WBUSINESS,
      title: "Budget Fixing Based On Your Goal",
      subtitle:
        "We extensively understand the scope of your project and final goal. Even though...",
      button: "Read More",
      routeName: "Home",
    },
    {
      id:4,
      image: COMMUNICATIVE,
      hoverimage: WCOMMUNICATIVE,
      title: "Good Communicative Squard",
      subtitle:
        "Best choice for the good communication as setting up extra calls and sendings...",
      button: "Read More",
      routeName: "Home",
    },
    {
      
      id:5,
      
      image: MARGET,
      hoverimage: WMARGET,
      title: "Best Market Credibility",
      subtitle:
        "We implement the marketing strategy from different types of angle based on client...",
      button: "Read More",
      routeName: "Home",
    },
    {
      id:6,
      image: PHILOSOPHY,
      hoverimage: WPHILOSOPHY,
      title: "Philosophy of Success",
      subtitle:
        "We build a good relationship with the client. This is the key stone for success of both parties...",
      button: "Read More",
      routeName: "Home",
    },
  ];
  const Specials = specials.map((special) => (
    <Col key={special.id} lg={4} md={6} sm={6} xs={12} className="">
      <Specialization
        routeName={special.routeName}
        title={special.title}
        subtitle={special.subtitle}
        image={special.image}
        button={special.button}
        bgColor={special.bgColor}
      />
    </Col>
  ));
  const Whyus = whyustg.map((whyus) => (
    <Col key={whyus.id} lg={4} md={6} sm={6} xs={12} className="">
      <WhyTechno
        routeName={whyus.routeName}
        title={whyus.title}
        subtitle={whyus.subtitle}
        image={whyus.image}
        hoverimage={whyus.hoverimage}
        button={whyus.button}
      />
    </Col>
  ));
  const Logos = logos.map((logo) => (
    <div  className="item" key={logo.id}>
      <img src={logo.image} className="clientLogo" alt="client" />
    </div>
  ));
  const Works = works.map((work, ) => (
    <Carousel.Item key={work.id}>
      <img src={work.image} className="" alt="recent work" />
    </Carousel.Item>
  ));
  return (
    <>
      <Navigation />
      <div className="homepage">
        {/* Banner */}
        <Banner
          image={BannerImg}
          title1=" You to"
          title2="Grow Your"
          title3="Business"
          subtitle2="will be distracted by the readable"
          subtitle1="It is a long established fact that a reader"
          button="Get a Proposal"
        />
        {/* Client Logo */}
        <Fade right>
        <div className="client-logo">
          <Container>
            <OwlCarousel
              className="owl-theme"
              loop={false}
              margin={10}
              items={5}
              nav={false}
              dots={false}
              center={false}
              responsive={state.responsive}
            >
              {Logos}
            </OwlCarousel>
          </Container>
        </div>
        </Fade>
        {/* Your Dream */}
        
        <div className="your-dream">
        <Fade top>
          <h1 className="homepage-title">
            Your <span className="themeColor">dream</span> made a reality, on any
            platform!
          </h1>
          <p className="homepage-subtitle">
            Having built over 120+ apps on almost every single platform and
            device, we can make your dream a reality
          </p>
          </Fade >
          <img src={DREAM} alt="Your Dream" />
        </div>
       

        {/* Specializations */}
        <div className="specialization">
          <Container>
          <Fade top>
            <h1 className="homepage-title">Our Specializations</h1>
            <p className="homepage-subtitle">
              We delivering photo print{" "}
              <span className="themeColor">
                <b>lowest price</b>
              </span>
            </p>
            </Fade >
            <Row className="mt-60">{Specials}</Row>
          </Container>
        </div>
        {/* Recent Works */}
        <div className="recent-works">
          <h1 className="homepage-title">
            Our Recent Great Work
            <img src={CIRCLE1} alt="circle 1" className="circle-1" />
          </h1>

          <p className="homepage-subtitle">
            <img src={CIRCLE2} alt="circle 2" className="circle-2" />
            We delivering photo print{" "}
            <span className="themeColor">lowest price</span>
          </p>
          <div className="recent-work-images">
            <Carousel>{Works}</Carousel>
            <img src={BGIMAGE} alt="bg-image" className="bgimage" />
          </div>
        </div>
        {/* Why Technogenesis */}
        <div className="why-technogenesis">
          <Container>
          <Fade left>
            <h1 className="homepage-title px-105">
              Why <span className="themeColor">Techno Genesis</span> will be a great
              fit for your software Development?
            </h1>
            </Fade >

            <Row className="mt-130">{Whyus}</Row>
          </Container>
        </div>
        {/* Lets start */}

        <Contactus />
        {/* Testimonials */}
        <div className="testimonial">
          <Testimonials />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
