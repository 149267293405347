import React, { useEffect } from "react";
import Contactus from "../component/layout/contactus";
import Footer from "../component/layout/footer";
import Navigation from "../component/layout/header";
import Testimonials from "../component/layout/testimonials";
import TitleBanner from "../component/layout/title-banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { BLOGBANNER } from "../assets/images/index";
import { IoChatbubblesOutline, IoCalendarOutline } from "react-icons/io5";
import {
  BsPinterest,
  BsLinkedin,
  BsFacebook,
  BsTwitter,
  BsArrowLeft,
} from "react-icons/bs";
import { ImQuotesLeft } from "react-icons/im";
import "../assets/styles/blogdetail.css";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { BsArrowRight } from "react-icons/bs";
import Fade from "react-reveal/Fade";

const Blogdetail = () => {
  const blogpost = [
    {
      image: BLOGBANNER,
      title1: "Regional Manager & limited management",
      date: "July 24, 2021",
    },
    {
      image: BLOGBANNER,
      title1: "Regional Manager & limited management",
      date: "July 24, 2021",
    },
    {
      image: BLOGBANNER,
      title1: "Regional Manager & limited management",
      date: "July 24, 2021",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navigation />
      <TitleBanner bgtitle={"BLOG"} title={"BLOG DETAILS"} />
      <Container>
        <Row>
          <Col>
            <div></div>
            <div className="blog_detail_banner_image">
              <Fade bottom>
                <Image src={BLOGBANNER} fluid alt="img" />
              </Fade>
              <div className="blog_detail_banner_image_details d-flex">
                <div className="d-flex alignItemEnd blog_detail_banner_image_details_icons ml-10">
                  <h5>
                    <IoChatbubblesOutline className="blog_detail_banner_image_details_icon_1" />
                  </h5>
                  <h5 className="ml-15">
                    <span>0</span> Comments
                  </h5>
                </div>
                <div className="d-flex alignItemEnd blog_detail_banner_image_details_icons">
                  <h5 className="">
                    <IoCalendarOutline className="blog_detail_banner_image_details_icon_2" />
                  </h5>
                  <h5 className="ml-15">July 24, 2001</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={7}>
            <Fade left delay={1000} opposite ={true}>
            <div className="blog_details_main_section">
              <h2 className="blog_details_main_section_header">
                Regional Manager & limited time management.
              </h2>
              <p className="blog_details_main_section_contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
                delectus corrupti veritatis atque commodi dignissimos unde vitae
                illum voluptatibus, temporibus, iure impedit! Ratione architecto
                doloremque sed cupiditate repudiandae nesciunt enim.
              </p>

              <Button variant="dark" className="readmoro_btn">
                Read More
                <BsArrowRight
                  className="readmoro_btn_icon"
                  style={{ marginLeft: 20 }}
                />
              </Button>

              <Image className="mt-40" src={BLOGBANNER} fluid alt="img" />

              <p className="blog_details_main_section_contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
                delectus corrupti veritatis atque commodi dignissimos unde vitae
                illum voluptatibus, temporibus, iure impedit! Ratione architecto
                doloremque sed cupiditate repudiandae nesciunt enim.
              </p>

              <div className="blog_details_main_section_contents_quotes_container alignItemCenter">
                <div className="d-flex">
                  <h1 className="blog_details_main_section_contents_quotes">
                    <sup>
                      <ImQuotesLeft />
                    </sup>
                  </h1>
                  <div>
                    <h2 className="blog_details_main_section_contents_quotes">
                      If you want to shine like a sun, first burn like a sun.
                    </h2>
                    <p className="blog_details_main_section_contents_quotes_author">
                      - A. P. J. Abdul Kalam
                    </p>
                  </div>
                </div>
              </div>

              <p className="blog_details_main_section_contents">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
                delectus corrupti veritatis atque commodi dignissimos unde vitae
                illum voluptatibus, temporibus, iure impedit! Ratione architecto
                doloremque sed cupiditate repudiandae nesciunt enim Lorem, ipsum
                dolor sit amet consectetur adipisicing elit. Nemo fugiat
                blanditiis expedita id, rem asperiores ratione quibusdam
                repellat maiores deleniti, maxime doloremque dolore deserunt
                odit consequatur magni sequi dolor. Similique.
              </p>

              <div className="d-flex share_container alignItemCenter mt-25">
                <h4 className="">Share:</h4>
                <div className="d-flex icon-set">
                  <h3 className="share_containericon ">
                    <BsPinterest className="pinterest_icon share_containericon" />
                  </h3>
                  <h3 className="share_containericon">
                    <BsLinkedin className="linked_icon share_containericon" />
                  </h3>
                  <h3 className="share_containericon">
                    <BsFacebook className="facebook_icon share_containericon" />
                  </h3>
                  <h3 className="share_containericon">
                    <BsTwitter className="twitter_icon share_containericon" />
                  </h3>
                </div>
              </div>
              <div className="next_blog_container">
                <div>
                  <BsArrowLeft className="next_blog_container_arrow" />
                  <p>Previous Post</p>
                </div>
                <div>
                  <p>Next Post</p>
                  <BsArrowRight className="next_blog_container_arrow" />
                </div>
              </div>
              <div className="leave_comment_section">
                <h1>Leave A Comment</h1>

                <div className="leave_comment_section_comment_box">
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>

                  <Form>
                    <Form.Group className="mb-3" controlId="ControlTextarea1">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Write Your Comment *"
                      />
                    </Form.Group>

                    <div className="d-flex spaceBetween">
                      <Form.Group
                        className="mb-3 w-40 new_unput"
                        controlId="ControlName"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Name *"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 w-40 new_unput"
                        controlId="ControlEmail"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Email Address *"
                        />
                      </Form.Group>
                    </div>

                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      className="save_check"
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />

                    <Button className="post_commt_btn w-100">
                      POST COMMENT
                      <BsArrowRight
                        className="readmoro_btn_icon"
                        style={{ marginLeft: 6 }}
                      />
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            </Fade>
           
          </Col>

          <Col lg={{ offset: 1 }}>
          <Fade right delay={1000}>
          <div className="blog_details_Recent_post_section moving_section">
              <h2 className="blog_details_Recent_post_section_header">
                Recent Posts
              </h2>

              {blogpost.map((blogpost, i) => {
                return (
                  <div
                    className="blog_details_Recent_post_section_posts"
                    key={i}
                  >
                    <img src={blogpost.image} alt="image" />

                    <div className="blog_details_Recent_post_section_posts_content">
                      <h3>{blogpost.title1}</h3>

                      <div className="d-flex blog_post_details_icons">
                        <h5 className="">
                          <IoCalendarOutline className="blog_detail_banner_post_details_icon" />
                        </h5>
                        <h5 className="ml-10">{blogpost.date}</h5>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fade>
           
          </Col>
        </Row>
      </Container>

      <Contactus />
      <Testimonials />
      <Footer />
    </>
  );
};

export default Blogdetail;
