import React from "react";
import "../assets/styles/about.css";
import Card from "react-bootstrap/Card";
import { EMPLOYEE, CLIENT, PROJECTS, EMPLOYEES } from "../assets/images/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CountUp from "react-countup";

const OurHighlights = (props) => {
  const cardInfo = [
    {
      title: "EMPLOYEE",
      image: EMPLOYEE,
      bgcolor: "#E3CDF2",
      count: 100,
      name: "Employees",
      item: "hover_set_first",
      duration: "2",
    },
    {
      title: "CLIENT",
      image: CLIENT,
      bgcolor: "#E5FAFD",
      count: 50,
      name: "Clients",
      item: "hover_set_second",
      duration: "2",
    },
    {
      title: "PROJECTS",
      image: PROJECTS,
      bgcolor: "#FEDCDA",
      count: 100,
      name: "Projects",
      item: "hover_set_third",
      duration: "2",
    },
    {
      title: "EMPLOYEES",
      image: EMPLOYEES,
      bgcolor: "#E5E9FF",
      count: 50,
      name: "Employees",
      item: "hover_set_fourth",
      duration: "2",
    },
  ];
  return (
    <div className="box" style={{ marginTop: "50px", marginBottom: "9%" }}>
      <Container>
        <h1
          className=" font heading-our-hilights"
          style={{ fontWeight: "bold" }}
        >
          Our Highlights
        </h1>
        <Row>
          {cardInfo.map((cardInfo, index) => {
            // card section
            return (
              <Col sm={6} md={6} lg={3} key={index}>
                <Card className={cardInfo.item}>
                  <div className="hover_action"></div>
                  <div
                    className="highlights_icon"
                    style={{
                      background: cardInfo.bgcolor,
                      height: "68px",
                      width: "68px",
                      borderRadius: "50%",
                    }}
                  >
                    <Card.Img
                      className="icon_style"
                      style={{ width: "1.7rem" }}
                      src={cardInfo.image}
                    />
                  </div>
                  <Card.Body style={{ padding: 0, textAlign: "center" }}>
                    <Card.Title className="title_style mt-16">
                      <CountUp
                        start={0}
                        end={cardInfo.count}
                        duration={4.75}
                        suffix="+"
                      />
                    </Card.Title>
                    <Card.Text className="text_style">
                      {cardInfo.name}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
export default OurHighlights;
