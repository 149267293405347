import React from "react";
import Card from 'react-bootstrap/Card';
import { TGQUOTES } from "../../assets/images/index"
const Lifeoftgcard = ({
    profile,
    content1,
    profileName,
    domain
}) => {
    return (
        <Card className="tg-card">
        <Card.Img className="tg-profile" src= {profile} />
        <Card.Body className="tg-content">
            <div className="quotes">
            <img src={TGQUOTES} alt=""/>
            </div>
          <Card.Text>
            {content1}
          </Card.Text>
          <Card.Title>{profileName}</Card.Title>
          <p> {domain}</p>
        </Card.Body>
      </Card>
    )
}

export default Lifeoftgcard;